.teacher-homepage-container {
	display: flex;
	justify-content: space-around;
	align-items: center;
	padding: 20px;
	gap: 20px;
	flex-wrap: wrap;
}

.homepage-card {
	background-color: var(--light-rosa);
	border: 2px solid var(--lila);
	border-radius: 8px;
	padding: 20px;
	max-width: 300px;
	text-align: center;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
	transition: box-shadow 0.3s, transform 0.2s;
}

.homepage-card:hover {
	box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
	transform: translateY(-5px);
}

.card-link {
	text-decoration: none;
	color: var(--black);
}

.homepage-card h3 {
	margin: 0 0 10px 0;
	color: var(--lila);
}

.homepage-card p {
	margin: 5px 0;
}

/* Responsive adjustments for mobile screens */
@media (max-width: 600px) {
	.teacher-homepage-container {
		flex-direction: column;
		align-items: stretch;
	}
}
