#canvas-parent-container {
	position: relative;
	justify-content: center;
	height: 130px;
	display: flex;
	align-items: flex-start; /* Keeps items aligned at the top */
	margin-top: 30px;
}

#canvas-parent-container canvas {
	position: absolute;
	left: 50%;
	top: 0; /* Aligns top edge with container top */
	transform: translateX(-50%); /* Only shifts horizontally */
}

#replay-button {
	all: unset;
	flex-shrink: 0;
	border-radius: 50%;
	border-color: var(--black);
	background-color: var(--lila);
	width: 20px;
	height: 20px;
	z-index: 11;

	/* Flexbox styles to center content */
	display: none;
	justify-content: center;
	align-items: center;

	/* Additional styling for vertical centering */
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	color: var(--white);
}

#replay-line {
	position: absolute;
	height: 130px;
	width: 2px;
	background-color: coral;
	z-index: 10;
	display: none;
}
