.show-course-container {
	max-width: 800px;
	margin: 20px auto;
	padding: 20px;
	padding-bottom: 0px;
	background-color: var(--light-rosa);
	border: 2px solid var(--lila);
	border-radius: 8px;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.show-course-header {
	color: var(--lila);
	text-align: center;
	margin-bottom: 20px;
}

.show-course-detail {
	margin: 10px 0;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.show-course-detail strong {
	color: var(--black);
	margin-right: 10px;
}

.show-course-input,
.show-course-select {
	padding: 8px;
	border: 1px solid var(--lila);
	border-radius: 4px;
	width: 50%;
}

.show-course-input-date {
	max-width: 120px;
}

.show-course-button {
	padding: 8px 16px;
	background-color: var(--rosa);
	color: var(--white);
	border: none;
	border-radius: 4px;
	cursor: pointer;
	transition: background-color 0.3s;
}

.show-course-button:hover {
	background-color: var(--lila);
}

.course-students,
.course-todo-dates {
	background-color: var(--white);
	padding: 15px;
	margin-top: 20px;
	border-radius: 4px;
}

.course-students {
	border: 1px dashed var(--lila);
}

.course-todo-dates {
	border: 1px dashed var(--rosa);
}

.course-debug-information {
	text-align: center;
}

.back-to-overview-container {
	max-width: 800px;
	margin: 20px auto;
	padding: 20px;
	background-color: var(--light-rosa);
	border: 2px solid var(--lila);
	border-radius: 8px;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
	text-align: center;
}
