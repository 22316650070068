/* General Privacy Container Styling */
.privacy-container {
	max-width: 800px;
	margin: 20px auto;
	padding: 20px;
	background-color: var(--white-rosa);
	border: 2px solid var(--lila);
	border-radius: 8px;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Privacy Content Styling */
.privacy-content {
	font-size: 16px;
	color: var(--black);
	text-align: justify;
}

.privacy-content h2 {
	margin-top: 20px;
	color: var(--lila);
}

.privacy-content h3 {
	margin-top: 15px;
	color: var(--black);
}

.privacy-content p,
.privacy-content ul {
	margin-bottom: 20px;
}

.privacy-content ul {
	list-style-type: disc;
	margin-left: 20px;
}

.privacy-content a {
	color: var(--lila);
	text-decoration: underline;
}

.privacy-content a:hover {
	color: var(--rosa);
}
