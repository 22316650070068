/* START: AUDIO CONTROLS */
/*source: https://codepen.io/shahednasser/pen/XWgbGBN*/

.audio-player-container {
	--player-button-width: 3em;
	--space: 0.5em;
	width: 20rem;
	height: 3rem;
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	margin: auto;
}

.icon-container {
	width: 100%;
	height: 100%;
	background-color: var(--rosa);
	color: #fff;
	display: flex;
	justify-content: center;
	align-items: center;
}

.audio-icon {
	width: 90%;
	height: 90%;
}

.controls {
	display: flex;
	flex-direction: row;
	align-items: center;
	width: 100%;
	margin-top: 10px;
	justify-content: center;
}

.player-button {
	all: unset;
	display: flex; /* Enables flexbox */
	justify-content: center; /* Centers content horizontally */
	align-items: center; /* Centers content vertically */
	background-color: var(--rosa);
	color: var(--white);
	border-radius: 50%;
	width: 30px;
	height: 30px;
	cursor: pointer;
	padding: 0;
	margin-right: 5px;
}

.timeline {
	-webkit-appearance: none;
	appearance: none;
	width: calc(100% - (var(--player-button-width) + var(--space)));
	height: 0.5em;
	background-color: #e5e5e5;
	border-radius: 5px;
	background-size: 0% 100%;
	background-image: linear-gradient(var(--rosa), var(--rosa));
	background-repeat: no-repeat;
	margin-right: var(--space);
	display: none;
}

.timeline::-webkit-slider-thumb {
	-webkit-appearance: none;
	appearance: none;
	width: 1em;
	height: 1em;
	border-radius: 50%;
	cursor: pointer;
	opacity: 0;
	transition: all 0.1s;
	background-color: var(--lila);
}

.timeline::-moz-range-thumb {
	-webkit-appearance: none;
	appearance: none;
	width: 1em;
	height: 1em;
	border-radius: 50%;
	cursor: pointer;
	opacity: 0;
	transition: all 0.1s;
	background-color: var(--lila);
}

.timeline::-ms-thumb {
	-webkit-appearance: none;
	appearance: none;
	width: 1em;
	height: 1em;
	border-radius: 50%;
	cursor: pointer;
	opacity: 0;
	transition: all 0.1s;
	background-color: var(--lila);
}

.timeline::-webkit-slider-thumb:hover {
	background-color: var(--lila);
}

.timeline:hover::-webkit-slider-thumb {
	opacity: 1;
}

.timeline::-moz-range-thumb:hover {
	background-color: var(--lila);
}

.timeline:hover::-moz-range-thumb {
	opacity: 1;
}

.timeline::-ms-thumb:hover {
	background-color: var(--lila);
}

.timeline:hover::-ms-thumb {
	opacity: 1;
}

.timeline::-webkit-slider-runnable-track {
	-webkit-appearance: none;
	box-shadow: none;
	border: none;
	background: transparent;
}

.timeline::-moz-range-track {
	-webkit-appearance: none;
	appearance: none;
	box-shadow: none;
	border: none;
	background: transparent;
}

.timeline::-ms-track {
	-webkit-appearance: none;
	appearance: none;
	box-shadow: none;
	border: none;
	background: transparent;
}

#synth-text {
	font-size: small;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	display: inherit;
}

#synthesize-speech-button {
	position: absolute;
	background-color: var(--rosa);
	border-radius: 30px;
	width: 15rem;
}

.transition-move {
	transition: top 0.5s ease-in-out, height 0.5s ease-in-out;
}
