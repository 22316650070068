.recording-button-container {
	z-index: 2;
	overflow: visible;
	position: relative;
	justify-content: center;
	max-width: 800px;
	width: 95%;
	display: flex;
	margin-top: -150px;
}

/* Firefox-specific styles */
@-moz-document url-prefix() {
	.recording-button-container {
		margin-top: -130px; /* Specific for Firefox  / I do not understand why but otherwise it is not aligned */
	}
}

.recording-button {
	position: relative;
	z-index: 2;
	/*background-color: #4CAF50; */
	background-color: var(--rosa);
	/* round glow outside */
	box-shadow: 10 10 10 10 var(--rosa);
	border: none;
	color: white;
	text-align: center;
	text-decoration: none;
	font-size: 16px;
	border-radius: 50%;
	width: 130px;
	height: 130px;
	transition: top 1s;
	display: flex; /* This was missing */
	justify-content: center; /* Centers children horizontally */
	align-items: center; /* Centers children vertically */
	transition: ease-in-out 0.3s;
}

.recording-button:disabled {
	color: var(--rosa);
}

.cancel-recording-button {
	position: absolute;
	right: calc(50% - 200px);
	top: 50%;
	transform: translateY(-50%);
	z-index: 1;
	transition: opacity 0.3s ease;
}

.glow-on-hover img {
	width: 60%;
	height: 60%;
}

.glow-on-hover i,
.glow-on-hover img {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.glow-on-hover:before {
	content: "";
	/*background: linear-gradient(45deg, #ff0000, #ff7300, #fffb00, #48ff00, #00ffd5, #002bff, #7a00ff, #ff00c8, #ff0000);*/
	/*background: linear-gradient(45deg, yellow, red, MediumVioletRed, black); | IDEA FOR GERMANY FLAG BACKGROUND*/
	background: linear-gradient(
		45deg,
		var(--rosa),
		MediumVioletRed,
		var(--lila)
	);
	overflow: visible;
	position: absolute;
	top: -3px;
	left: -3px;
	background-size: 300%;
	z-index: -1;
	filter: blur(5px);
	width: calc(100% + 6px);
	height: calc(100% + 6px);
	animation: glowing 20s linear infinite;
	opacity: 0.7;
	transition: opacity 0.3s ease-in-out;
	border-radius: 50%;
}

.glow-on-hover:active:after {
	top: +2px;
	left: +2px;
	height: calc(100% - 4px);
}

.glow-on-hover:hover:before {
	opacity: 1;
	width: calc(100% + 12px);
	height: calc(100% + 12px);
	top: -6px;
	left: -6px;
}

.glow-on-hover:after {
	z-index: -1;
	content: "";
	position: absolute;
	width: 100%;
	height: 100%;
	background: var(--rosa);
	left: 0;
	top: 0;
	color: white;
	border-radius: 50%;
}

.glow-on-hover:disabled:after {
	background: var(--white);
	border: none;
}

.glow-on-hover:disabled:before {
	background: white;
	filter: none;
	border: none;
}

@keyframes glowing {
	0% {
		background-position: 0 0;
	}
	50% {
		background-position: 300% 0;
	}
	100% {
		background-position: 0 0;
	}
}

.buttons-below-aussprachetrainer {
	display: flex;
	width: min(95%, 800px);
	justify-content: space-between; /* Align items to the right */
}

