.bulk-students-input {
	width: 50%;
	padding: 4px;
	margin-top: 5px;
	border: 1px solid var(--lila);
	border-radius: 4px;
	margin-left: 5px;
	max-width: 80px;
}

.bulk-students-input:focus {
	outline: none;
	border-color: var(--lila);
	box-shadow: 0 0 10px var(--lila);
}

.bulk-students-button {
	padding: 5px 5px;
	background-color: var(--rosa);
	color: var(--white);
	border: none;
	border-radius: 4px;
	cursor: pointer;
	transition: background-color 0.3s;
	margin-left: 8px;
}

.bulk-students-button:hover {
	background-color: var(--lila);
}
