.notifications-container {
    position: fixed;
    top: 20px;
    right: 20px;
    z-index: 10000; /* High z-index to ensure it's above other content */
    display: flex;
    flex-direction: column;
    align-items: flex-end; /* Align notifications to the right */
  }
  
  .notification {
    background-color: #f44336; /* Default red for errors */
    color: white;
    padding: 12px 20px;
    margin-bottom: 10px; /* Space between notifications */
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.2);
    transition: all 0.3s ease;
    max-width: 300px; /* Ensure notifications don't get too wide */
  }
  
  .notification.success {
    background-color: #4CAF50; /* Green for success notifications */
  }
  
  .notification.error {
    background-color: #f44336; /* Red for error notifications */
  }
  