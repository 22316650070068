/* Style for the password reset form */
.reset-password-form {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 20px;
	max-width: min(600px, 95%); /* Keeps the form well-sized for readability */
	margin: 20px auto;
	background-color: var(--white-rosa);
	border: 2px solid var(--rosa);
	border-radius: 8px;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.reset-password-form h1 {
	font-size: 20px;
	margin-bottom: 20px;
}

.reset-password-form label {
	font-size: 16px;
	color: var(--black);
	margin-bottom: 15px;
	width: 100%; /* Ensures alignment of label and input */
}

.reset-password-form input {
	width: calc(100% - 20px);
	padding: 10px;
	border: 1px solid var(--rosa);
	border-radius: 4px;
	background-color: var(--white);
	color: var(--black);
	margin-top: 5px;
}

.reset-password-form input:focus {
	outline: none;
	border-color: var(--lila);
	box-shadow: 0 0 10px var(--lila);
}

.reset-password-form button {
	padding: 10px 20px;
	background-color: var(--rosa);
	color: var(--white);
	border: none;
	border-radius: 4px;
	cursor: pointer;
	transition: background-color 0.3s;
	margin-top: 15px;
}

.reset-password-form button:hover {
	background-color: var(--lila);
}

.reset-password-form p {
	font-size: 14px;
	color: var(--black);
	margin-top: 20px;
}
