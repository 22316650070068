.todo-dates-header {
	text-align: center;
	margin-top: 1vh;
	
}

.course-todo-dates {
	margin-top: 20px;
	padding: 15px;
	background-color: var(--white-rosa);
	border-left: 5px solid var(--lila);
	border-radius: 4px;
}

.todo-date-heading {
	font-size: 18px;
	color: var(--rosa);
	margin-bottom: 10px;
}

.todo-date-detail {
	margin-bottom: 10px;
}

.todo-date-label {
	color: var(--black);
}

.todo-date-info {
	padding: 5px 0;
	color: var(--black);
}

.todo-date-edit {
	margin-top: 10px;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.todo-date-input {
	border: 1px solid var(--lila);
	border-radius: 4px;
	padding: 8px;
	width: 120px;
}

.todo-date-button {
	padding: 6px 12px;
	background-color: var(--rosa);
	color: var(--white);
	border: none;
	border-radius: 4px;
	cursor: pointer;
	transition: background-color 0.3s;
	transform: translateY(25px);
	word-wrap: break-word;
	white-space: normal;
}

.todo-date-button:not(:disabled):hover {
	background-color: var(--lila);
}

.todo-date-button:disabled {
	cursor: default;
	filter: grayscale(0.3);
	opacity: 0.3;
}

.todo-date-button:not(:disabled) {
	opacity: 1;
}

.date-input-container {
	display: inline-flex;
	flex-direction: column;
	margin-right: 34px;
}

.todo-date-group {
	display: flex;
	/*align-items: flex-start; /* Aligns items to the top */
	margin-top: 20px;
	padding: 15px;
	background-color: var(--white-rosa);
	border-left: 5px solid var(--lila);
	border-radius: 4px;
}

.todo-index-display {
	font-size: 24px;
	color: var(--lila);
	margin-right: 20px;
	flex-shrink: 0;
	justify-content: center;
	display: flex;
	flex-direction: column;
}

.todo-dates-content {
	flex-grow: 1;
}

.todo-dates-training {
	display: block;
	outline: var(--lila) solid 1px;
	border-radius: 10px;
	outline-offset: 10px;
	margin-bottom: 40px;
}

.todo-date-training-week {
	margin-bottom: 5px;
	margin-top: 30px;
}

.todo-dates-training.currently-active-tododate {
	outline: var(--green) solid 3px;
}

.todo-dates-training.passed-tododate {
	outline: var(--black) solid 1px;
}

/* styles on mobile */
@media (max-width: 768px) {
	.todo-date-group {
		flex-direction: column;
	}
	.date-input-container {
		margin-right: 5px;
	}

	.todo-date-input {
		width: 100%;
	}

	.todo-date-input {
		border: 1px solid var(--lila);
		border-radius: 4px;
		padding: 4px;
		width: 120px;
	}

	.todo-date-button {
		transform: translateY(5px);
		padding: 6px 8px;
	}
}
