/* General Form Container Styling */
.final-questionnaire-form-container {
	max-width: 800px;
	margin: 20px auto;
	padding: 20px;
	background-color: var(--white-rosa);
	border: 2px solid var(--lila);
	border-radius: 8px;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Input and Label Grouping */
.final-questionnaire-form-input {
	display: flex;
	flex-direction: column;
	margin-bottom: 20px;
}

.final-questionnaire-form-input input,
.final-questionnaire-form-input select {
	padding: 8px 10px;
	margin-top: 8px;
	border: 2px solid var(--lila);
	border-radius: 4px;
	font-size: 16px;
}

/* Button Specific Styling */
.submit-button {
	padding: 8px 16px;
	background-color: var(--rosa);
	color: var(--white);
	border: none;
	border-radius: 4px;
	cursor: pointer;
	transition: background-color 0.3s;
	display: block;
	width: 100%;
	font-size: 18px;
}

/* Button Hover Effect */
.submit-button:hover {
	background-color: var(--lila);
}

/* Focus Effect on Inputs */
.final-questionnaire-form-input input:focus,
.final-questionnaire-form-input select:focus {
	outline: none;
	border-color: var(--lila);
	box-shadow: 0 0 10px var(--rosa);
}

/* Notification Styling */
.data-notification {
	max-width: 800px;
	margin: 20px auto;
	padding: 20px;
	background-color: var(--light-rosa);
	border: 2px solid var(--lila);
	border-radius: 8px;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
	font-size: 16px;
	color: var(--black);
	text-align: justify;
}

/* Range Input Specific Styling */
.range-input {
	position: relative;
}

.range-labels {
	display: flex;
	justify-content: space-between;
	font-size: small;
	margin: 10px 7px -20px;
}
