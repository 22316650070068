#response-area-scores {
	height: auto;
	white-space: normal;
	text-align: center;
	vertical-align: top;
	padding-left: 0.5em;
	padding-right: 0.5em;
	padding-top: 0.2em;
	padding-bottom: 0.2em;
	background-color: var(--light-rosa);
	font-size: smaller;
	margin-bottom: 10px;
}

#responsearea {
	padding-left: 0.5em;
	padding-right: 0.5em;
	padding-top: 0.2em;
	padding-bottom: 0.2em;
	vertical-align: middle;
	border-radius: 25px;
	border: solid 4px #aa6bfd;
	outline: none;
	height: auto;
	white-space: normal;
	text-align: left;
	vertical-align: top;
	max-width: min(95%, 800px);
	margin-top: 10px;
}

.response-word {
	font-size: max(min(1.5vw, 24px), 20px);
	font-family: "Montserrat";
	font-weight: 400;
	display: inline-block;
	margin-right: 5px;
}

.black-word {
	/* better to make it green */
	color: darkgreen;
}

.darkred-word {
	color: darkred;
}

.yellow-word {
	color: gold;
}
