:root {
	--lila: #7e20fc;
	--rosa: #aa6bfd;
	--white: #ffffff;
	--red: Red;
	--darkred: #990000;
	--black: Black;
	--white-rosa: #fbf9fe;
	--light-rosa: #ecdffe;
	--yellow: #ffd700;
	--green: #008000;
}

.green {
	color: var(--green);
}

@font-face {
	font-family: "Montserrat";
	src: url("./fonts/Montserrat-Regular.ttf");
}

body {
	font-family: "Montserrat", sans-serif;
}

.required {
	color: var(--darkred);
}

.pointer {
	cursor: pointer;
}

/* Individual items */

button {
	padding: 8px 16px;
	background-color: var(--rosa);
	color: var(--white);
	border: none;
	border-radius: 4px;
	cursor: pointer;
	transition: background-color 0.3s;
}

button:hover {
	background-color: var(--lila);
}

a:hover {
	color: var(--lila);
}

.hidden {
	visibility: hidden;
}

input[type="range"] {
	accent-color: var(--rosa);
}
